<template>
  <div>
    <BoxedPage title="Ajoutez vos photos de Kim et/ou Mao">
      <div v-if="!uploading" class="text-center">
        <label class="upload">
          <p>Cliquez ici ou faites glisser des fichiers pour les envoyer</p>
          <input
            type="file"
            multiple="multiple"
            @change="fileInputChange($event)"
            :disabled="uploading"
            v-show="false"
          />
        </label>
        <p v-if="currentUser.role !== 'admin'">
          <small
            >Les fichiers uploadés ne seront visibles qu'après avoir été
            acceptés par un administrateur du site</small
          >
        </p>
      </div>
      <div v-else>
        <p v-if="!uploadDone">
          Upload en cours:
          {{ uploadProgress }} sur {{ uploadTotal }} fichiers
        </p>
        <p v-else>Upload terminé</p>
        <p v-if="uploadErrors.length" @click="viewErrors = !viewErrors">
          <template v-if="uploadErrors.length === 1">1 erreur</template>
          <template v-else>{{ uploadErrors.length }} erreurs</template>
          <span v-if="viewErrors"> (masquer)</span>
          <span v-else> (afficher)</span>
        </p>
        <ul v-if="viewErrors">
          <li v-for="error in uploadErrors" :key="error.file.name">
            {{ error.file.name }} -
            <span v-if="error.error === 'ERROR_MATCHING_CHECKSUMS'"
              >Ce fichier existe déjà</span
            >
            <span v-if="error.error === 'ERROR_MATCHING_CHECKSUMS_UPDATED'"
              >Ce fichier existe déjà</span
            >
          </li>
        </ul>
        <button v-if="uploadDone" @click="uploading = false">Retour</button>
      </div>
    </BoxedPage>
    <div class="dropzone" ref="dropzone" v-show="dragging">
      <p>Déposez vos fichiers ici</p>
    </div>
  </div>
</template>

<script>
import BoxedPage from "../components/BoxedPage.vue";

export default {
  name: "Upload",
  components: {
    BoxedPage,
  },
  data() {
    return {
      uploading: false,
      uploadProgress: 0,
      uploadTotal: 0,
      uploadErrors: [],
      uploadDone: false,
      viewErrors: false,
      dragging: false,
      dragLeaveTimeout: null,
    };
  },
  mounted() {
    this.$el.addEventListener(
      "dragenter",
      () => {
        clearTimeout(this.dragLeaveTimeout);
        this.dragging = true;
      },
      false
    );
    this.$el.addEventListener(
      "dragleave",
      () => {
        clearTimeout(this.dragLeaveTimeout);
        this.dragLeaveTimeout = setTimeout(() => {
          this.dragging = false;
        }, 100);
      },
      false
    );
    this.$el.addEventListener(
      "dragover",
      () => {
        clearTimeout(this.dragLeaveTimeout);
        this.dragging = true;
      },
      false
    );
    this.$refs.dropzone.addEventListener("dragover", (e) => {
      e.preventDefault();
    });
    this.$refs.dropzone.addEventListener(
      "drop",
      (e) => {
        e.preventDefault();
        this.dragging = false;
        this.upload(e.dataTransfer.files);
      },
      false
    );
  },
  methods: {
    fileInputChange(event) {
      this.upload(event.target.files);
    },
    upload(files) {
      this.uploading = true;
      this.uploadTotal = files.length;
      this.uploadProgress = 0;
      this.uploadErrors = [];
      this.viewErrors = false;
      this.uploadDone = false;
      Array.from(files)
        .reduce((promise, file) => {
          return promise.then(() => {
            const formData = new FormData();
            formData.append("file", file);
            formData.set("lastModified", file.lastModified);
            return this.api.Media.create(formData)
              .then(() => {
                this.uploadProgress++;
              })
              .catch((err) => {
                this.uploadProgress++;
                this.uploadErrors.push({ file: file, error: err.message });
              });
          });
        }, Promise.resolve())
        .then(() => {
          this.uploadDone = true;
        });
    },
    back() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss">
.dropzone {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 20px solid transparent;
  box-sizing: border-box;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 3px dashed lightgrey;
    background: rgba(255, 255, 255, 0.9);
    z-index: -1;
  }

  p {
    font-size: 10vh;
  }
}
</style>
