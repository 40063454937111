<template>
  <div class="view with-header">
    <div class="view-header">
      <div class="view-header-row">
        <select v-model="year">
          <option value="">{{ year ? "Tout" : "Quand ?" }}</option>
          <option v-for="y in years" :value="y" :key="y">{{ y }}</option>
        </select>
        <select v-model="month" v-if="year" style="margin-left: 10px">
          <option value="">{{ month ? "Tout" : "Mois" }}</option>
          <option v-for="m in months" :value="m.value" :key="m.name">
            {{ m.name }}
          </option>
        </select>
      </div>
      <div class="view-header-row">
        <select v-model="type">
          <option value="">{{ type ? "Tout" : "Quoi ?" }}</option>
          <option value="image">Photo</option>
          <option value="video">Video</option>
        </select>
        <select v-model="kid" style="margin-left: 10px">
          <option value="">{{ kid ? "Tout" : "Qui ?" }}</option>
          <option value="kim">Kim</option>
          <option value="mao">Mao</option>
          <option value="both">Les deux</option>
        </select>
      </div>
    </div>
    <div class="view-inner" ref="scrollEl">
      <CenterContent v-if="fetching && currentPage === 0">
        <h2>Chargement</h2>
      </CenterContent>
      <CenterContent v-else-if="!fetching && medias.length === 0">
        <h2>Aucun media correspondant</h2>
        <p>
          <router-link to="upload">Vous pouvez en ajouter ici</router-link>
        </p>
      </CenterContent>
      <template v-if="!fetching || currentPage > 0">
        <Media
          v-for="(media, index) in medias"
          :key="media._id"
          :media="media"
          @press="open(index)"
        ></Media>
      </template>
      <div ref="paginateEl"></div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { debounce } from "throttle-debounce";
import CenterContent from "../components/CenterContent.vue";
import Media from "../components/Media.vue";

export default {
  name: "Home",
  components: {
    CenterContent,
    Media,
  },
  data() {
    return {
      medias: null,
      currentPage: 0,
      total: null,
      fetching: false,
      year: "",
      month: "",
      kid: "",
      type: "",
    };
  },
  created() {
    this.fetchMedias(true);
    this._onScrollDebounce = debounce(300, this.onScroll);
  },
  mounted() {
    this.$refs.scrollEl.addEventListener("scroll", this._onScrollDebounce);
  },
  methods: {
    fetchMedias(reset) {
      this.fetching = true;
      if (reset) {
        this.currentPage = 0;
      } else {
        this.currentPage++;
      }
      const queryData = { limit: 24, page: this.currentPage };
      if (this.month) {
        const date = dayjs(
          `${this.year}-${("00" + this.month).substr(-2)}-01T12:00:00.000Z`
        );
        queryData.from = date.startOf("month").format();
        queryData.to = date.endOf("month").format();
      } else if (this.year) {
        const date = dayjs(`${this.year}-01-01T12:00:00.000Z`);
        queryData.from = date.startOf("year").format();
        queryData.to = date.endOf("year").format();
      }

      if (this.kid) {
        const kim = this.kid === "kim" || this.kid === "both";
        const mao = this.kid === "mao" || this.kid === "both";
        if (kim) {
          queryData.kim = true;
        }
        if (mao) {
          queryData.mao = true;
        }
      }

      if (this.type) {
        queryData.type = this.type;
      }

      this.api.Media.query(queryData).then((response) => {
        if (reset) {
          this.medias = response.medias;
        } else {
          this.medias = (this.medias || []).concat(...response.medias);
        }
        this.total = response.total;
        this.fetching = false;
      });
    },
    onScroll() {
      if (!this.fetching && this.medias.length < this.total) {
        const box = this.$refs.paginateEl.getBoundingClientRect();
        if (box.top < this.$refs.scrollEl.offsetHeight + 200) {
          this.fetchMedias();
        }
      }
    },
    open(index) {
      this.$root.$emit("open-media", {
        medias: this.medias,
        index: index,
      });
    },
  },
  computed: {
    years() {
      const currentYear = dayjs().year();
      const years = [];
      for (let i = 2017; i <= currentYear; i++) {
        years.push(i);
      }
      return years;
    },
    months() {
      const months = [
        "janvier",
        "février",
        "mars",
        "avril",
        "mai",
        "juin",
        "juillet",
        "août",
        "septembre",
        "octobre",
        "novembre",
        "décembre",
      ];
      let start = 1;
      let end = 12;
      if (this.year === dayjs().year()) {
        end = dayjs().month() + 1;
      } else if (this.year === 2017) {
        start = 2;
      }
      return months
        .map((m, index) => ({ name: m, value: index + 1 }))
        .filter((mObj) => mObj.value >= start && mObj.value <= end);
    },
  },
  watch: {
    year() {
      if (this.month === "") {
        this.fetchMedias(true);
      }
      this.month = "";
    },
    month() {
      this.fetchMedias(true);
    },
    kid() {
      this.fetchMedias(true);
    },
    type() {
      this.fetchMedias(true);
    },
  },
};
</script>
